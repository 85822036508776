// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';

// const SignupScreen = () => {
//   const navigate = useNavigate();

//   const [username, setUsername] = useState('');
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const [mobile, setMobile] = useState('');
//   const [membershipNo, setMembershipNo] = useState('');
//   const [club, setClub] = useState('');
//   const [district, setDistrict] = useState('');
  
//   const districts = [
//     '316a', '316b', '316d', '31g', '316h', '316j', 
//     '320a', '320b', '320c', '320d', '320e', '320f', '320g', '320h', 
//     '321 D', '321 E', '321 F', '321 A1', '321 A2', '321 A3', '321 B1', 
//     '321 B2', '321 C1', '321 C2', '322 A', '322 B1', '322 B2', '322 D', 
//     '322 E', '322 F', '322 G', '322 H', '322 C1', '322 C2', '3231A1', 
//     '3231A2', '3231A3', '3231A4', '3232 J', '3232B1', '3232B2', '3232B3', 
//     '3232B4', '3232F1', '3232F2', '3233C', '3233E1', '3233E2', '3233G1', 
//     '3234D1', '3234D2', '3234H1', '3234H2', '324 A', '324B', '324C', 
//     '324D', '324E', '324F', '324G', '324H', '324I', '324J', '324K', 
//     '324L', '324M', '324N'
//   ];

//   const handleSignup = async () => {
//     if (!username || !email || !password || !mobile || !membershipNo || !district || !club) {
//       alert('Please fill in all fields');
//       return;
//     }

//     try {
//       const response = await axios.post('http://192.168.1.8:3000/api/otp/signup', {
//         username,
//         email,
//         password,
//         mobile,
//         membershipNo,
//         district,
//         club,
//       });
//       if (response.status === 200) {
//         alert('User registered successfully');
//         navigate('/otp-verification', { state: { email } });
//       } else {
//         alert(response.data.message || 'Something went wrong');
//       }
//     } catch (error) {
//       console.error(error);
//       alert('Error: ' + (error.response?.data?.error || 'Something went wrong'));
//     }
//   };

//   return (
//     <div style={styles.container}>
//       <div style={styles.formContainer}>
//         <h1 style={styles.title}>Signup</h1>
        
//         <input 
//           type="text" 
//           placeholder="Username" 
//           value={username} 
//           onChange={(e) => setUsername(e.target.value)} 
//           style={styles.input} 
//         />

//         <input 
//           type="email" 
//           placeholder="Email" 
//           value={email} 
//           onChange={(e) => setEmail(e.target.value)} 
//           style={styles.input} 
//         />

//         <input 
//           type="password" 
//           placeholder="Password" 
//           value={password} 
//           onChange={(e) => setPassword(e.target.value)} 
//           style={styles.input} 
//         />

//         <input 
//           type="text" 
//           placeholder="Mobile" 
//           value={mobile} 
//           onChange={(e) => setMobile(e.target.value)} 
//           style={styles.input} 
//         />

//         <select 
//           value={district} 
//           onChange={(e) => setDistrict(e.target.value)} 
//           style={styles.input}
//         >
//           <option value="">Select a District</option>
//           {districts.map((district, index) => (
//             <option key={index} value={district}>{district}</option>
//           ))}
//         </select>

//         <input 
//           type="text" 
//           placeholder="Membership Number" 
//           value={membershipNo} 
//           onChange={(e) => setMembershipNo(e.target.value)} 
//           style={styles.input} 
//         />

//         <input 
//           type="text" 
//           placeholder="Club" 
//           value={club} 
//           onChange={(e) => setClub(e.target.value)} 
//           style={styles.input} 
//         />

//         <button style={styles.signupButton} onClick={handleSignup}>
//           Signup
//         </button>
//       </div>
//     </div>
//   );
// };

// const styles = {
//   container: {
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     height: '100vh',
//     backgroundColor: '#f7f7f7',
//     padding: '20px',
//   },
//   formContainer: {
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'center',
//     alignItems: 'center',
//     width: '100%',
//     maxWidth: '400px',
//     backgroundColor: '#ffffff',
//     borderRadius: '10px',
//     padding: '30px',
//     boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//   },
//   title: {
//     fontSize: '30px',
//     fontWeight: 'bold',
//     color: '#2A9D8F',
//     textAlign: 'center',
//     marginBottom: '25px',
//   },
//   input: {
//     width: '100%',
//     padding: '15px',
//     marginBottom: '15px',
//     border: '1px solid #ddd',
//     borderRadius: '25px',
//     fontSize: '16px',
//     backgroundColor: '#f4f4f4',
//     transition: 'border-color 0.3s ease',
//   },
//   inputFocus: {
//     borderColor: '#2A9D8F',
//   },
//   signupButton: {
//     width: '100%',
//     padding: '15px',
//     backgroundColor: '#2A9D8F',
//     color: '#fff',
//     borderRadius: '25px',
//     fontSize: '18px',
//     fontWeight: 'bold',
//     border: 'none',
//     cursor: 'pointer',
//     transition: 'background-color 0.3s ease',
//   },
//   signupButtonHover: {
//     backgroundColor: '#1b7f70',
//   },
// };

// export default SignupScreen;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Select from 'react-select'; // Import react-select for dropdown

const SignupScreen = () => {
  const navigate = useNavigate();

  const [name, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [mobile, setMobile] = useState('');
  const [membershipNo, setMembershipNo] = useState('');
  const [club, setClub] = useState('');
  const [district, setDistrict] = useState('');
  
  const districts = [
   '316A', '316B', '316D', '316G', '316H', '316J',
   '317A', '317B', '317C', '317D', '317E', '317F', '317G',
   '318A', '318B','318C', '318D', '318E',
   '320A', '320B', '320C', '320D', '320E', '320F', '320G', '320H', 
   '321D', '321E', '321F', '321A1', '321A2', '321A3', '321B1', '321B2', '321C1', '321C2', 
   
    '322A', '322B1', '322B2', '322D', '322E', '322F', '322G', '322H', '322C1', '322C2', 
    '3231A1', '3231A2','3231A3', '3231A4', 
    '3232J', '3232B1', '3232B2', '3232B3','3232B4', '3232F1', '3232F2',
    '3233C', '3233E1', '3233E2', '3233G1', 
    '3234D1', '3234D2', '3234H1', '3234H2', '324A', '324B', '324C', 
    '324D', '324E', '324F', '324G', '324H', '324I', '324J', '324K', 
    '324L', '324M', '324N',
  ];

  const handleSignup = async () => {
    if (!name || !email || !password || !district) {
      alert('Please fill in all fields');
      return;
    }

    try {
      const response = await axios.post('https://lionsnewsapi.emedha.in/admin/adminSignup', {
        name,
        email,
        password,
      
        district,
       
      });
      if (response.status === 200) {
        alert('User registered successfully');
        navigate('/otp-form', { state: { email } });
      } else {
        alert(response.data.message || 'Something went wrong');
      }
    } catch (error) {
      console.error(error);
      alert('Error: ' + (error.response?.data?.error || 'Something went wrong'));
    }
  };

  // Format the dropdown items as { label: string, value: string }
  const districtOptions = districts.map(district => ({ label: district, value: district }));

  return (
    <div style={styles.container}>
      <div style={styles.formContainer}>
        <h1 style={styles.title}>Signup</h1>
        
        <input 
          type="text" 
          placeholder="name" 
          value={name} 
          onChange={(e) => setUsername(e.target.value)} 
          style={styles.input} 
        />

        <input 
          type="email" 
          placeholder="Email" 
          value={email} 
          onChange={(e) => setEmail(e.target.value)} 
          style={styles.input} 
        />

        <input 
          type="password" 
          placeholder="Password" 
          value={password} 
          onChange={(e) => setPassword(e.target.value)} 
          style={styles.input} 
        />

     
           <Select
          value={district ? { label: district, value: district } : null} // Display the selected district
          onChange={(selectedOption) => setDistrict(selectedOption.value)} // Handle selection
          options={districtOptions} // The list of districts as options
          placeholder="Select a District"
          styles={{
            control: (provided) => ({
              ...provided,
              width: '100%',
              padding: '15px',
              marginBottom: '15px',
              border: '1px solid #ddd',
              borderRadius: '25px',
              fontSize: '16px',
              backgroundColor: '#f4f4f4',
              transition: 'border-color 0.3s ease',
            }),
            menu: (provided) => ({
              ...provided,
              marginTop: '0',
              zIndex: '9999', // Ensure it appears above other content
            }),
            option: (provided) => ({
              ...provided,
              fontSize: '16px',
            }),
          }}
        />

        

        <button style={styles.signupButton} onClick={handleSignup}>
          Signup
        </button>
        <button
            style={styles.forgotPasswordLink}
            onClick={() => navigate('/')}
          >
           Back to Login
          </button>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f7f7f7',
    padding: '20px',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    maxWidth: '400px',
    backgroundColor: '#ffffff',
    borderRadius: '10px',
    padding: '30px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  title: {
    fontSize: '30px',
    fontWeight: 'bold',
    color: '#2A9D8F',
    textAlign: 'center',
    marginBottom: '25px',
  },
  input: {
    width: '100%',
    padding: '15px',
    marginBottom: '15px',
    border: '1px solid #ddd',
    borderRadius: '25px',
    fontSize: '16px',
    backgroundColor: '#f4f4f4',
    transition: 'border-color 0.3s ease',
  },
  signupButton: {
    width: '100%',
    padding: '15px',
    backgroundColor: '#2A9D8F',
    color: '#fff',
    borderRadius: '25px',
    fontSize: '18px',
    fontWeight: 'bold',
    border: 'none',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
  forgotPasswordLink: {
    marginTop: '15px',
    color: '#2A9D8F',
    fontSize: '14px',
    textDecoration: 'underline',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    textAlign: 'center',
  },
};

export default SignupScreen;

// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import Select from 'react-select'; // Import react-select for dropdown

// const SignupScreen = () => {
//   const navigate = useNavigate();

//   const [username, setUsername] = useState('');
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const [mobile, setMobile] = useState('');
//   const [membershipNo, setMembershipNo] = useState('');
//   const [club, setClub] = useState('');
//   const [district, setDistrict] = useState('');
  
//   const districts = [
//     '316a', '316b', '316d', '31g', '316h', '316j', 
//     '320a', '320b', '320c', '320d', '320e', '320f', '320g', '320h', 
//     '321 D', '321 E', '321 F', '321 A1', '321 A2', '321 A3', '321 B1', 
//     '321 B2', '321 C1', '321 C2', '322 A', '322 B1', '322 B2', '322 D', 
//     '322 E', '322 F', '322 G', '322 H', '322 C1', '322 C2', '3231A1', 
//     '3231A2', '3231A3', '3231A4', '3232 J', '3232B1', '3232B2', '3232B3', 
//     '3232B4', '3232F1', '3232F2', '3233C', '3233E1', '3233E2', '3233G1', 
//     '3234D1', '3234D2', '3234H1', '3234H2', '324 A', '324B', '324C', 
//     '324D', '324E', '324F', '324G', '324H', '324I', '324J', '324K', 
//     '324L', '324M', '324N'
//   ];

//   const handleSignup = async () => {
//     if (!username || !email || !password || !mobile || !membershipNo || !district || !club) {
//       alert('Please fill in all fields');
//       return;
//     }

//     try {
//       const response = await axios.post('http://192.168.1.8:3000/api/otp/signup', {
//         username,
//         email,
//         password,
//         mobile,
//         membershipNo,
//         district,
//         club,
//       });
//       if (response.status === 200) {
//         alert('User registered successfully');
//         navigate('/otp-verification', { state: { email } });
//       } else {
//         alert(response.data.message || 'Something went wrong');
//       }
//     } catch (error) {
//       console.error(error);
//       alert('Error: ' + (error.response?.data?.error || 'Something went wrong'));
//     }
//   };

//   // Format the dropdown items as { label: string, value: string }
//   const districtOptions = districts.map(district => ({ label: district, value: district }));

//   return (
//     <div style={styles.container}>
//       <div style={styles.formContainer}>
//         <h1 style={styles.title}>Signup</h1>
        
//         <input 
//           type="text" 
//           placeholder="Username" 
//           value={username} 
//           onChange={(e) => setUsername(e.target.value)} 
//           style={styles.input} 
//         />

//         <input 
//           type="email" 
//           placeholder="Email" 
//           value={email} 
//           onChange={(e) => setEmail(e.target.value)} 
//           style={styles.input} 
//         />

//         <input 
//           type="password" 
//           placeholder="Password" 
//           value={password} 
//           onChange={(e) => setPassword(e.target.value)} 
//           style={styles.input} 
//         />

//         <input 
//           type="text" 
//           placeholder="Mobile" 
//           value={mobile} 
//           onChange={(e) => setMobile(e.target.value)} 
//           style={styles.input} 
//         />

//         {/* Wrap Club and District fields in a flex container */}
//         <div style={styles.row}>
//           <input 
//             type="text" 
//             placeholder="Club" 
//             value={club} 
//             onChange={(e) => setClub(e.target.value)} 
//             style={{ ...styles.input, width: '40%' }} // 48% width to allow spacing between inputs
//           />
//           <Select
//             value={district ? { label: district, value: district } : null} // Display the selected district
//             onChange={(selectedOption) => setDistrict(selectedOption.value)} // Handle selection
//             options={districtOptions} // The list of districts as options
//             placeholder="Select a District"
//             styles={{
//               control: (provided) => ({
//                 ...provided,
//                 width: '55',
//                 // height:'0%',
//                 // padding: '15px',
//                 marginBottom: '15px',
//                 border: '1px solid #ddd',
//                 cursor:'pointer',
//                 borderRadius: '25px',
//                 fontSize: '16px',
//                 backgroundColor: '#f4f4f4',
//                 transition: 'border-color 0.3s ease',
//               }),
//               menu: (provided) => ({
//                 ...provided,
//                 marginTop: '0',
//                 zIndex: '9999', // Ensure it appears above other content
//               }),
//               option: (provided) => ({
//                 ...provided,
//                 fontSize: '16px',
//               }),
//             }}
//           />
//         </div>

//         <input 
//           type="text" 
//           placeholder="Membership Number" 
//           value={membershipNo} 
//           onChange={(e) => setMembershipNo(e.target.value)} 
//           style={styles.input} 
//         />

//         <button style={styles.signupButton} onClick={handleSignup}>
//           Signup
//         </button>
//       </div>
//     </div>
//   );
// };

// const styles = {
//   container: {
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     height: '100vh',
//     backgroundColor: '#f7f7f7',
//     padding: '20px',
//   },
//   formContainer: {
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'center',
//     alignItems: 'center',
//     width: '100%',
//     maxWidth: '400px',
//     backgroundColor: '#ffffff',
//     borderRadius: '10px',
//     padding: '30px',
//     boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//   },
//   title: {
//     fontSize: '30px',
//     fontWeight: 'bold',
//     color: '#2A9D8F',
//     textAlign: 'center',
//     marginBottom: '25px',
//   },
//   input: {
//     width: '100%',
//     padding: '15px',
//     marginBottom: '15px',
//     border: '1px solid #ddd',
//     borderRadius: '25px',
//     fontSize: '16px',
//     backgroundColor: '#f4f4f4',
//     transition: 'border-color 0.3s ease',
//   },
//   row: {
//     display: 'flex',
//     justifyContent: 'space-between',
//     width: '100%',
//   },
//   signupButton: {
//     width: '100%',
//     padding: '15px',
//     backgroundColor: '#2A9D8F',
//     color: '#fff',
//     borderRadius: '25px',
//     fontSize: '18px',
//     fontWeight: 'bold',
//     border: 'none',
//     cursor: 'pointer',
//     transition: 'background-color 0.3s ease',
//   },
// };

// export default SignupScreen;
