// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// // src/index.js
// import 'bootstrap/dist/css/bootstrap.min.css';

// const LoginPage = () => {
//   const [id, setId] = useState('');
//   const [password, setPassword] = useState('');
//   const [error, setError] = useState('');
//   const navigate = useNavigate();

// //   const handleLogin = () => {
// //     // Default credentials
// //     const defaultId = 'adminlion';
// //     const defaultPassword = 'lionsNews@123';

// //     if (id === defaultId && password === defaultPassword) {
// //       // Store user data in sessionStorage
// //       sessionStorage.setItem('userId', id);
// //       sessionStorage.setItem('password', password);

// //       // Navigate to HomePage on successful login
// //       navigate('/Dashboard');
// //     } else {
// //       // Show alert on incorrect credentials
// //       setError('Invalid ID or Password');
// //     }
// //   };

// // src/components/LoginPage.js
// const handleLogin = () => {
//     // Default credentials
//     const defaultId = 'adminlion';
//     const defaultPassword = 'lionsNews@123';
  
//     if (id === defaultId && password === defaultPassword) {
//       // Store user data in sessionStorage
//       sessionStorage.setItem('userId', id);
//       sessionStorage.setItem('password', password);
  
//       // Navigate to Dashboard on successful login
//       navigate('/dashboard');
//     } else {
//       // Show alert on incorrect credentials
//       setError('Invalid ID or Password');
//     }
//   };
  

// return (
//     <div
//       className="d-flex justify-content-center align-items-center"
//       style={{
//         height: '100vh', // Full viewport height
//       }}
//     >
//       <div
//         className="card shadow"
//         style={{
//           width: '100%',
//           maxWidth: '400px',
//           padding: '40px',
//           borderRadius: '15px',
//           boxShadow: '0px 8px 15px rgba(42, 157, 143, 0.5)', // Shadow color #2A9D8F with transparency
//         }}
//       >
//         <h2 className="text-center mb-4" style={{ fontWeight: '500' }}>
//           Login
//         </h2>
//         {error && <p className="text-danger text-center mb-4">{error}</p>}
//         <div className="mb-3">
//           <label className="form-label">Username</label>
//           <input
//             type="text"
//             className="form-control"
//             value={id}
//             onChange={(e) => setId(e.target.value)}
//             placeholder="Enter your username"
//             style={{
//               backgroundColor: '#f9f9f9', // Light gray background for the input
//               borderColor: '#ccc', // Light gray border
//             }}
//           />
//         </div>
//         <div className="mb-3">
//           <label className="form-label">Password</label>
//           <input
//             type="password"
//             className="form-control"
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//             placeholder="Enter your password"
//             style={{
//               backgroundColor: '#f9f9f9', // Light gray background for the input
//               borderColor: '#ccc', // Light gray border
//             }}
//           />
//         </div>
//         <button
//           onClick={handleLogin}
//           className="btn btn-primary w-100 mt-3"
//           style={{
//             borderRadius: '8px', // Rounded corners for the button
//           }}
//         >
//           Login
//         </button>
//       </div>
//     </div>
//   );
// };

// export default LoginPage;


// import React, { useState } from 'react';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';

// const LoginScreen = () => {
//   const navigate = useNavigate();
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');

//   const handleLogin = async () => {
//     if (!email || !password) {
//       alert('Please enter email and password');
//       return;
//     }

//     try {
//       const response = await axios.post('http://localhost:3000/api/otp/login', { email, password });
//       console.log('API Response:', response.data);

//       if (response.status === 200) {
//         const { token, user } = response.data;
//         console.log('user data ', user);

//         if (user) {
//           // Store user data in localStorage
//           localStorage.setItem('userData', JSON.stringify(user));
//           console.log('Stored User Data:', user);
//         }

//         // Store the auth token
//         localStorage.setItem('authToken', token);

//         // Navigate to home screen after successful login
//         alert('Login Successful');
//         navigate('/Dashboard');
//       }
//     } catch (error) {
//       console.error(error);
//       alert('Login failed');
//     }
//   };

//   return (
//     <>
//       <div style={styles.container}>
//         {/* Login Form */}
//         <div style={styles.formContainer}>
//           <h1 style={styles.title}>Login</h1>

//           <input
//             type="email"
//             placeholder="Email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             style={styles.input}
//           />

//           <input
//             type="password"
//             placeholder="Password"
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//             style={styles.input}
//           />

//           <button style={styles.loginButton} onClick={handleLogin}>
//             Login
//           </button>
//           <button
//             style={styles.forgotPasswordLink}
//             onClick={() => navigate('/signup')}
//           >
//             New Admin Account Creation
//           </button>
//           <button
//             style={styles.forgotPasswordLink}
//             onClick={() => navigate('/forgot-password')}
//           >
//             Forgot Password?
//           </button>
//         </div>
//       </div>

//     </>
//   );
// };

// const styles = {
//   container: {
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     height: '100vh',
//     backgroundColor: '#f7f7f7',
//     padding: '20px',
//   },
//   formContainer: {
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'center',
//     alignItems: 'center',
//     width: '100%',
//     maxWidth: '400px',
//     backgroundColor: '#ffffff',
//     borderRadius: '10px',
//     padding: '30px',
//     boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//   },
//   title: {
//     fontSize: '30px',
//     fontWeight: 'bold',
//     color: '#2A9D8F',
//     textAlign: 'center',
//     marginBottom: '25px',
//   },
//   input: {
//     width: '100%',
//     padding: '15px',
//     marginBottom: '15px',
//     border: '1px solid #ddd',
//     borderRadius: '25px',
//     fontSize: '16px',
//     backgroundColor: '#f4f4f4',
//     transition: 'border-color 0.3s ease',
//   },
//   inputFocus: {
//     borderColor: '#2A9D8F',
//   },
//   loginButton: {
//     width: '100%',
//     padding: '15px',
//     backgroundColor: '#2A9D8F',
//     color: '#fff',
//     borderRadius: '25px',
//     fontSize: '18px',
//     fontWeight: 'bold',
//     border: 'none',
//     cursor: 'pointer',
//     transition: 'background-color 0.3s ease',
//   },
//   loginButtonHover: {
//     backgroundColor: '#1b7f70',
//   },
//   forgotPasswordLink: {
//     marginTop: '15px',
//     color: '#2A9D8F',
//     fontSize: '14px',
//     textDecoration: 'underline',
//     background: 'none',
//     border: 'none',
//     cursor: 'pointer',
//     textAlign: 'center',
//   },
// };

// export default LoginScreen;


import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const LoginScreen = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // const handleLogin = async () => {
  //   if (!email || !password) {
  //     alert('Please enter email and password');
  //     return;
  //   }

  //   try {
  //     const response = await axios.post('http://localhost:3000/api/otp/login', { email, password });
  //     console.log('API Response:', response.data);

  //     if (response.status === 200) {
  //       const { token, user } = response.data;
  //       console.log('user data ', user);

  //       if (user) {
  //         // Store user data in sessionStorage
  //         sessionStorage.setItem('userData', JSON.stringify(user));
  //         console.log('Stored User Data in sessionStorage:', user);
  //       }

  //       // Store the auth token in sessionStorage
  //       sessionStorage.setItem('authToken', token);

  //       // Navigate to home screen after successful login
  //       alert('Login Successful');
  //       navigate('/Dashboard');
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     alert('Login failed');
  //   }
  // };


  const handleLogin = async () => {
    if (!email || !password) {
      alert('Please enter email and password');
      return;
    }
  
    try {
      const response = await axios.post('https://lionsnewsapi.emedha.in/admin/login', { email, password });
      console.log('API Response:', response.data);
  
      if (response.status === 200) {
        const { token, user } = response.data;
  
        if (user) {
          // Store user data and userId in sessionStorage
          sessionStorage.setItem('userId', user.id); // Ensure user.id is correct
          sessionStorage.setItem('userData', JSON.stringify(user)); // Save entire user object
          sessionStorage.setItem('authToken', token);  // Store auth token as well
          sessionStorage.setItem('userDistrict', user.district);
          
          console.log('User authenticated, sessionStorage set');
        }
  
        // Redirect to Dashboard after successful login
        alert('Login Successful');
        navigate('/Dashboard');  // Make sure this works as expected
      }
    } catch (error) {
      console.error(error);
      alert('Login failed');
    }
  };
  

  return (
    <>
      <div style={styles.container}>
        {/* Login Form */}
        <div style={styles.formContainer}>
          <h1 style={styles.title}>Login</h1>

          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={styles.input}
          />

          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={styles.input}
          />

          <button style={styles.loginButton} onClick={handleLogin}>
            Login
          </button>
          <button
            style={styles.forgotPasswordLink}
            onClick={() => navigate('/signup')}
          >
            New Admin Account Creation
          </button>
          <button
            style={styles.forgotPasswordLink}
            onClick={() => navigate('/forgot-password')}
          >
            Forgot Password?
          </button>
        </div>
      </div>
    </>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f7f7f7',
    padding: '20px',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    maxWidth: '400px',
    backgroundColor: '#ffffff',
    borderRadius: '10px',
    padding: '30px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  title: {
    fontSize: '30px',
    fontWeight: 'bold',
    color: '#2A9D8F',
    textAlign: 'center',
    marginBottom: '25px',
  },
  input: {
    width: '100%',
    padding: '15px',
    marginBottom: '15px',
    border: '1px solid #ddd',
    borderRadius: '25px',
    fontSize: '16px',
    backgroundColor: '#f4f4f4',
    transition: 'border-color 0.3s ease',
  },
  inputFocus: {
    borderColor: '#2A9D8F',
  },
  loginButton: {
    width: '100%',
    padding: '15px',
    backgroundColor: '#2A9D8F',
    color: '#fff',
    borderRadius: '25px',
    fontSize: '18px',
    fontWeight: 'bold',
    border: 'none',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
  loginButtonHover: {
    backgroundColor: '#1b7f70',
  },
  forgotPasswordLink: {
    marginTop: '15px',
    color: '#2A9D8F',
    fontSize: '14px',
    textDecoration: 'underline',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    textAlign: 'center',
  },
};

export default LoginScreen;
