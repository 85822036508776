import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

const ResetPassword = () => {
  const navigate = useNavigate();

  const { resetToken } = useParams();


  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleResetPassword = async () => {
    if (!password || !confirmPassword) {
      alert('Please enter and confirm your new password');
      return;
    }

    if (password !== confirmPassword) {
      alert('Passwords do not match');
      return;
    }

    try {
      const response = await axios.post(`https://lionsnewsapi.emedha.in/admin/reset-password/${resetToken}`, { password });
      if (response.status === 200) {
        alert('Your password has been reset successfully');
        navigate('/');
      }
    } catch (error) {
      console.error(error);
      alert('Failed to reset password');
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.formContainer}>
        <h2 style={styles.title}>Admin Reset Password</h2>

        <input
          type="password"
          placeholder="New Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={styles.input}
        />

        <input
          type="password"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          style={styles.input}
        />

        <button style={styles.resetButton} onClick={handleResetPassword}>
          Reset Password
        </button>

      
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f7f7f7',
    padding: '20px',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    maxWidth: '400px',
    backgroundColor: '#ffffff',
    borderRadius: '10px',
    padding: '30px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  title: {
    fontSize: '30px',
    fontWeight: 'bold',
    color: '#2A9D8F',
    textAlign: 'center',
    marginBottom: '25px',
  },
  input: {
    width: '100%',
    padding: '15px',
    marginBottom: '15px',
    border: '1px solid #ddd',
    borderRadius: '25px',
    fontSize: '16px',
    backgroundColor: '#f4f4f4',
    transition: 'border-color 0.3s ease',
  },
  resetButton: {
    width: '100%',
    padding: '15px',
    backgroundColor: '#2A9D8F',
    color: '#fff',
    borderRadius: '25px',
    fontSize: '18px',
    fontWeight: 'bold',
    border: 'none',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
  backLink: {
    marginTop: '15px',
    color: '#2A9D8F',
    fontSize: '14px',
    textAlign: 'center',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
};

export default ResetPassword;
