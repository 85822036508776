// // src/components/ProtectedRoute.js
// import React from 'react';
// import { Route, Navigate } from 'react-router-dom';

// const ProtectedRoute = ({ element, ...rest }) => {
//   const isAuthenticated = sessionStorage.getItem('userId'); // Check if user is logged in

//   if (!isAuthenticated) {
//     // If not authenticated, redirect to login page
//     return <Navigate to="/" />;
//   }

//   return <Route {...rest} element={element} />;
// };

// export default ProtectedRoute;

// src/components/ProtectedRoute.js


import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ element, ...rest }) => {
  const isAuthenticated = sessionStorage.getItem('userId'); // Check if user is logged in

  if (!isAuthenticated) {
    // If not authenticated, redirect to login page
    return <Navigate to="/" />;
  }

  return element; // If authenticated, render the element passed
};

export default ProtectedRoute;
