// import React, { useState } from 'react';
// import axios from 'axios';
// import { useHistory } from 'react-router-dom'; // React Router for navigation

// const ForgotPassword = () => {
//   const [email, setEmail] = useState('');
//   const history = useHistory(); // Navigation

//   const handleForgotPassword = async () => {
//     if (!email) {
//       alert('Please enter your email');
//       return;
//     }

//     try {
//       const response = await axios.post('http://192.168.1.8:3000/api/otp/forgot-password', { email });
//       if (response.status === 200) {
//         alert('Password reset instructions have been sent to your email');
//         history.push('/login'); // Navigate back to the login screen after success
//       }
//     } catch (error) {
//       console.error(error);
//       alert('Failed to send password reset email');
//     }
//   };

//   return (
//     <div style={styles.container}>
//       <div style={styles.formContainer}>
//         <h2 style={styles.title}>Forgot Password</h2>

//         <input
//           type="email"
//           placeholder="Email"
//           value={email}
//           onChange={(e) => setEmail(e.target.value)}
//           style={styles.input}
//         />

//         <button style={styles.resetButton} onClick={handleForgotPassword}>
//           Reset Password
//         </button>

//         <button style={styles.backLink} onClick={() => history.goBack()}>
//           Back to Login
//         </button>
//       </div>
//     </div>
//   );
// };

// const styles = {
//   container: {
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     height: '100vh',
//     backgroundColor: '#f7f7f7',
//     padding: '20px',
//   },
//   formContainer: {
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'center',
//     alignItems: 'center',
//     width: '100%',
//     maxWidth: '400px',
//     backgroundColor: '#ffffff',
//     borderRadius: '10px',
//     padding: '30px',
//     boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//   },
//   title: {
//     fontSize: '30px',
//     fontWeight: 'bold',
//     color: '#2A9D8F',
//     textAlign: 'center',
//     marginBottom: '25px',
//   },
//   input: {
//     width: '100%',
//     padding: '15px',
//     marginBottom: '15px',
//     border: '1px solid #ddd',
//     borderRadius: '25px',
//     fontSize: '16px',
//     backgroundColor: '#f4f4f4',
//     transition: 'border-color 0.3s ease',
//   },
//   resetButton: {
//     width: '100%',
//     padding: '15px',
//     backgroundColor: '#2A9D8F',
//     color: '#fff',
//     borderRadius: '25px',
//     fontSize: '18px',
//     fontWeight: 'bold',
//     border: 'none',
//     cursor: 'pointer',
//     transition: 'background-color 0.3s ease',
//   },
//   backLink: {
//     marginTop: '15px',
//     color: '#2A9D8F',
//     fontSize: '14px',
//     textAlign: 'center',
//     background: 'none',
//     border: 'none',
//     cursor: 'pointer',
//     textDecoration: 'underline',
//   },
// };

// export default ForgotPassword;

import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Correct usage of navigation in React.js

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate(); // useNavigate for navigation

  const handleForgotPassword = async () => {
    if (!email) {
      alert('Please enter your email');
      return;
    }

    try {
      const response = await axios.post('https://lionsnewsapi.emedha.in/admin/forgot-password', { email });
      if (response.status === 200) {
        alert('Password reset instructions have been sent to your email');
        navigate('/'); // Navigate back to the login screen after success
      }
    } catch (error) {
      console.error(error);
      alert('Failed to send password reset email');
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.formContainer}>
        <h2 style={styles.title}>Forgot Password</h2>

        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={styles.input}
        />

        <button style={styles.resetButton} onClick={handleForgotPassword}>
          Reset Password
        </button>

        <button style={styles.backLink} onClick={() => navigate('/')}>
          Back to Login
        </button>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f7f7f7',
    padding: '20px',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    maxWidth: '400px',
    backgroundColor: '#ffffff',
    borderRadius: '10px',
    padding: '30px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  title: {
    fontSize: '30px',
    fontWeight: 'bold',
    color: '#2A9D8F',
    textAlign: 'center',
    marginBottom: '25px',
  },
  input: {
    width: '100%',
    padding: '15px',
    marginBottom: '15px',
    border: '1px solid #ddd',
    borderRadius: '25px',
    fontSize: '16px',
    backgroundColor: '#f4f4f4',
    transition: 'border-color 0.3s ease',
  },
  resetButton: {
    width: '100%',
    padding: '15px',
    backgroundColor: '#2A9D8F',
    color: '#fff',
    borderRadius: '25px',
    fontSize: '18px',
    fontWeight: 'bold',
    border: 'none',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
  backLink: {
    marginTop: '15px',
    color: '#2A9D8F',
    fontSize: '14px',
    textAlign: 'center',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
};

export default ForgotPassword;
