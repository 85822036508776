// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';

// const OtpVerification = () => {
//   const navigate = useNavigate(); // For navigation
//   const [email, setEmail] = useState('');
//   const [otp, setOtp] = useState('');

//   const handleVerifyOtp = async () => {
//     if (!otp || !email) {
//       alert('Please enter both email and OTP');
//       return;
//     }

//     try {
//       const response = await axios.post('http://localhost:3000/admin/verify-otp', { email, otp });
//       if (response.data.token) {
//         alert(response.data.message);
//         navigate('/'); // Navigate to login screen after successful OTP verification
//       } else {
//         alert('Invalid OTP. Please try again.');
//       }
//     } catch (error) {
//       console.error(error);
//       alert(error.response?.data?.error || 'Something went wrong');
//     }
//   };

//   return (
//     <>
//     <div style={styles.container}>
//       <h2 style={styles.heading}>Otp Verification</h2>

//       <input
//         type="email"
//         placeholder="Enter Email"
//         value={email}
//         onChange={(e) => setEmail(e.target.value)}
//         style={styles.input}
//       />
//       <input
//         type="text"
//         placeholder="Enter OTP"
//         value={otp}
//         onChange={(e) => setOtp(e.target.value)}
//         style={styles.input}
//         maxLength={6} // Optional: Limit to 6 digits for OTP
//       />
//       <button style={styles.verifyButton} onClick={handleVerifyOtp}>
//         Verify OTP
//       </button>
//     </div>
//     </>
//   );
// };

// const styles = {
//   container: {
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'center',
//     alignItems: 'center',
    
//     height: '100vh',
//     padding: '20px',
//     backgroundColor: '#fff',
//   },
//   heading: {
//     fontSize: '32px',
//     fontWeight: 'bold',
//     color: '#2A9D8F',
//     marginBottom: '30px',
//   },
//   input: {
//     width: '100%',
//     padding: '15px',
//     marginBottom: '15px',
//     borderRadius: '25px',
//     border: '1px solid #ccc',
//     backgroundColor: '#f9f9f9',
//     fontSize: '16px',
//   },
//   verifyButton: {
//     width: '100%',
//     padding: '15px',
//     backgroundColor: '#2A9D8F',
//     color: '#fff',
//     borderRadius: '25px',
//     fontSize: '18px',
//     fontWeight: 'bold',
//     border: 'none',
//     cursor: 'pointer',
//     transition: 'background-color 0.3s',
//   },
// };

// export default OtpVerification;
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const OtpVerificationScreen = () => {
  const navigate = useNavigate(); // For navigation
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');

  const handleVerifyOtp = async () => {
    if (!otp || !email) {
      alert('Please enter both email and OTP');
      return;
    }

    try {
      const response = await axios.post('https://lionsnewsapi.emedha.in/admin/verify-otp', { email, otp });
      if (response.data.token) {
        alert(response.data.message);
        navigate('/'); 
      } else {
        alert('Invalid OTP. Please try again.');
      }
    } catch (error) {
      console.error(error);
      alert(error.response?.data?.error || 'Something went wrong');
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.formContainer}>
        <h2 style={styles.title}>OTP Verification</h2>

        <input
          type="email"
          placeholder="Enter Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={styles.input}
        />
        <input
          type="text"
          placeholder="Enter OTP"
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
          style={styles.input}
          maxLength={6} // Optional: Limit to 6 digits for OTP
        />
        <button style={styles.button} onClick={handleVerifyOtp}>Verify OTP</button>

        <button onClick={() => navigate('/')} style={styles.backButton}>
          Back to Login
        </button>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f5f5f5',
    padding: '20px',
  },
  formContainer: {
    width: '100%',
    maxWidth: 400,
    backgroundColor: '#fff',
    borderRadius: '15px',
    padding: '25px',
    boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
  },
  title: {
    fontSize: '32px',
    fontWeight: 'bold',
    color: '#2A9D8F',
    marginBottom: '30px',
  },
  input: {
    width: '90%',
    padding: '15px',
    marginBottom: '15px',
    borderRadius: '25px',
    border: '1px solid #ccc',
    backgroundColor: '#f9f9f9',
    fontSize: '16px',
  },
  button: {
    width: '100%',
    padding: '15px',
    backgroundColor: '#2A9D8F',
    color: '#fff',
    borderRadius: '25px',
    fontSize: '18px',
    fontWeight: 'bold',
    border: 'none',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
  backButton: {
    width: '100%',
    padding: '15px',
    backgroundColor: '#f8f8f8',
    color: '#2A9D8F',
    borderRadius: '25px',
    fontSize: '16px',
    fontWeight: 'bold',
    border: 'none',
    cursor: 'pointer',
    marginTop: '15px',
  },
};

export default OtpVerificationScreen;
