// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;

// import React from 'react';
// import Dashboard from './Components/AdminDashboard/Dashboard'; // Assuming the Dashboard component is in the same folder

// const App = () => {
//   //const userId = 10; // Pass the appropriate userId
//   return (
//     <div>
//       <Dashboard/>
//     </div>
//   );
// };

// export default App;

// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


import Dashboard from './Components/AdminDashboard/Dashboard';
import LoginPage from './Components/Auth/Login';
import ProtectedRoute from './Components/ProtectedRoute/ProtectedRoute';
import Signup from './Components/Auth/Signup';
import ForgotPassword from './Components/Auth/ForgotPassword';
import ResetPassword from './Components/Auth/ResetPassword';
import OtpVerification from './Components/Auth/OtpVerification';
const App = () => {
  return (
    <Router>
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/otp-form" element={<OtpVerification />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/reset-password/:resetToken" element={<ResetPassword />} />

      {/* <Route path="/Dashboard"OtpVerification element={<Dashboard />} />  ForgotPassword*/}
      <Route
          path="/Dashboard"
          element={
            <ProtectedRoute  element={<Dashboard />}  /> } />
    </Routes>
  </Router>
  );
};

export default App;
